import "./App.css";
import React from "react";
import MainSearchMap from "./components/MainSearchMap";
import LandingPage from "./components/LandingPage";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Auth from "./components/Auth";
import Error404 from "./components/Error404";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      isAuthenticated: false,
      errorMsg: null,
      token: null,
    };
    this.doOtpAuth = this.doOtpAuth.bind(this);
  }

  /**
   * Try to authenticate using the OTP code.
   * This event is intended to be passed as a prop down to the child component
   * and then called on submit of the OTP code.
   * @param {string} code the otp auth code
   * @param {SyntheticBaseEvent} e the event
   */
  doOtpAuth(code, e) {
    fetch(`https://polaris.speedwelshpool.com:5000/authfromotp`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code: code }),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "SUCCESS") {
          localStorage.setItem("polaris-auth", JSON.stringify(json.data));
          this.setState({ isAuthenticated: true, errorMsg: null });
        } else {
          this.setState({ errorMsg: "Invalid code, try again" });
        }
      });
  }

  async authenticateFromLocalStorage() {
    const existingSession = JSON.parse(localStorage.getItem("polaris-auth"));

    if (existingSession) {
      // Verify its validity against the server
      const result = await fetch(
        `https://polaris.speedwelshpool.com:5000/vs/${existingSession.uuid}`
      );

      const json = await result.json();

      if (json.status === "VALID_SESSION") {
        this.setState({ isAuthenticated: true });
      }
    }
  }

  async authenticateFromToken(token) {
    fetch(`https://polaris.speedwelshpool.com:5000/auth/${token}`)
      .then((result) => result.json())
      .then((json) => {
        if (json.status === "SUCCESS") {
          localStorage.setItem("polaris-auth", JSON.stringify(json.data));
          this.setState({ isAuthenticated: true });
        }
      });
  }

  async componentDidMount() {
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("t")) {
      this.setState({ token: urlParams.get("t") });
    }

    await this.authenticateFromLocalStorage();

    if (!this.state.isAuthenticated) {
      if (urlParams.has("t") && urlParams.get("t").length > 0) {
        await this.authenticateFromToken(urlParams.get("t"));
      }
    }
  }

  render() {
    return (
      <Router>
        <Switch>
          {/** Main landing page. If authenticated, redirect */}
          <Route exact path="/">
            {this.state.isAuthenticated === true ? (
              <Redirect to="/m" />
            ) : (
                <LandingPage />
              )}
          </Route>

          <Route path="/m">
            {this.state.errorMsg ? (
              <div className="polaris-error">{this.state.errorMsg}</div>
            ) : (
                ""
              )}
            {this.state.isAuthenticated === true ? (
              <MainSearchMap />
            ) : (
                <Auth doOtpAuth={this.doOtpAuth} />
              )}
          </Route>

          <Route path="*">
            <Error404 />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
