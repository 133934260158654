const Address = ({ address, handleAddressClick }) => {

    return (
        <div onClick={ _ => handleAddressClick(address) }>
            {address.line_1}{' '}
            <small>{address.line_2}{' '}
                {address.line_3}{' '}</small>
        </div>
    )
}

export default Address;
