import React, { Component } from 'react';

class Autotab extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleTab = this.handleTab.bind(this);
        this.onFocus = this.onFocus.bind(this);
    }

    onFocus(e) {
        e.target.select();
    }

    handleChange(e) {
        this.props.onChange(e);
    }

    handleTab(e) {
        const target = e.target;
        const input = target.value;

        if (e.key === 'Backspace') {
            let prev = e.target.previousElementSibling;
            while (prev) {
                if (prev === null) break;
                if (prev.tagName.toLowerCase() === 'input') {
                    prev.focus();
                    break;
                }
            }
            return;
        }

        if (input.length >= this.props.maxLength) {
            let next = target;
            next = next.nextElementSibling;
            while (next) {
                if (next === null) break;
                if (next.tagName.toLowerCase() === 'input') {
                    next.focus();
                    break;
                }
            }
        }
    }

    render() {
        return (
            <input
                className='otp-input'
                type={this.props.type}
                name={this.props.name}
                placeholder={this.props.hint}
                maxLength={this.props.maxLength}
                defaultValue={this.props.value}
                onChange={this.handleChange}
                onFocus={this.onFocus}
                onKeyUp={this.props.maxLength ? this.handleTab : null}
                onKeyPress={this.props.onKeyPress || null}
                style={this.props.style}
                autoFocus={this.props.autoFocus}
            />
        );
    }
}

// Autotab.propTypes = {
//     name: PropTypes.string.isRequired,
//     onChange: PropTypes.func.isRequired,
//     type: PropTypes.string,
//     hint: PropTypes.string,
//     value: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.number,
//     ]),
//     maxLength: PropTypes.number,
//     style: PropTypes.object,
//     autoFocus: PropTypes.bool,
// };

export default Autotab;