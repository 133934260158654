import React from 'react';
import './MapControls.css';

const MapControls = ({ setSatelliteMap, setRoadsMap, setHybridMap}) => {
    return (
        <div className="controlArea column">
            <button className="button is-small" onClick={setSatelliteMap}>Satellite</button>
            <button className="button is-small" onClick={setRoadsMap}>Roads</button>
            <button className="button is-small" onClick={setHybridMap}>Hybrid</button>
        </div>
    );
}

export default MapControls;