import React from 'react';
import Autotab from './Autotab';
class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: {
                char1: '',
                char2: '',
                char3: '',
                char4: '',
                char5: '',
                char6: ''
            }

        };
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    codeStr() {
        return "".concat(...Object.values(this.state.code));
    }

    onSubmit(e) {
        let code = this.codeStr();
        this.props.doOtpAuth(code, e);
    }

    onKeyPress(e) {
        // Enter - submit
        if (e.charCode === 13 && this.codeStr().length === 6) {
            let code = this.codeStr();
            this.props.doOtpAuth(code, e);
        }
    }

    handleChange(event, fieldNum) {
        let str = event.target.value;

        // check if the string is not an empty string or number
        // this is all we are permitting
        if (!str.match(/[0-9]|^$/g)) {
            event.target.value = '';
            return;
        }

        this.setState({
            code: {
                ...this.state.code, ['char' + fieldNum]: str
            }
        });

    }

    render() {
        return (
            <>
                <div className="bigAmber">
                    <div className="bigEmoji">🚦</div>

                    <p style={{ fontSize: '0.5em', marginTop: '0.8em' }}>Please enter the pass-code</p>

                    <div className='container'>

                        <div className='otp-form'>
                            <div>

                                <Autotab type='tel' name='char[1]' maxLength={1} onChange={(e) => { this.handleChange(e, 1) }} autoFocus />
                                <Autotab type='tel' name='char[2]' maxLength={1} onChange={(e) => { this.handleChange(e, 2) }} />
                                <Autotab type='tel' name='char[3]' maxLength={1} onChange={(e) => { this.handleChange(e, 3) }} />
                                <Autotab type='tel' name='char[4]' maxLength={1} onChange={(e) => { this.handleChange(e, 4) }} />
                                <Autotab type='tel' name='char[5]' maxLength={1} onChange={(e) => { this.handleChange(e, 5) }} />
                                <Autotab type='tel' name='char[6]' maxLength={1} onChange={(e) => { this.handleChange(e, 6) }} onKeyPress={this.onKeyPress} />
                            </div>

                            <div>
                                <button
                                    className='button is-link is-large'
                                    disabled={this.codeStr().length !== 6}
                                    style={{ marginTop: '1em' }}
                                    onClick={this.onSubmit}
                                >Submit</button>
                            </div>

                        </div>
                    </div>
                    <p style={{ marginTop: '2em', fontSize: '0.5em' }}>Or, re-scan the QR code in the office</p>
                </div>
            </>
        );
    }
}

export default Auth;
