function Error404() {
  return (
    <>
      <div className="bigError">
        <div className="bigEmoji">👀</div>
        <div>
          <b>404 Page Not Found</b>
        </div>
      </div>
    </>
  );
}

export default Error404;
