import React from 'react';
import './Search.css';
import Address from './Address';

function Search({ searchResults, searchErrorMessage, onSearch, handleAddressClick, resultsVisible, handleSearchBoxOnFocus, handleSearchBoxOnBlur }) {

    return (
        <div className='searchArea column'>
            <input className='input'
                type='text'
                placeholder='Postcode search...'
                onKeyPress={(event) => { event.key === 'Enter' && onSearch(event.target.value); }}
                onFocus={handleSearchBoxOnFocus}
                onBlur={handleSearchBoxOnBlur}
            />

            {searchResults.length > 0 && resultsVisible === true ? (
                <div className='results'>
                    {searchResults.map((address, index) => (
                        <Address
                            key={index}
                            address={address}
                            handleAddressClick={handleAddressClick} />
                    ))}
                </div>
            ) : null}

            { searchErrorMessage.length > 0 &&
                <div className='searchError'>
                    {searchErrorMessage}
                </div>}
                     
        </div>
    );
}

export default Search;