import mapboxgl from "mapbox-gl";
import { Client as postcodeClient } from "@ideal-postcodes/core-browser";
import Search from "./Search";
import MapControls from "./MapControls";
import "bulma/css/bulma.css";
import React from "react";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

class MainSearchMap extends React.Component {
  render() {
    return (
      <>
        <Search
          searchResults={this.state.searchResults}
          searchErrorMessage={this.state.searchErrorMessage}
          onSearch={this.handleSearch}
          resultsVisible={this.state.resultsVisible}
          handleAddressClick={this.handleAddressClick}
          handleSearchBoxOnFocus={this.handleSearchBoxOnFocus}
          handleSearchBoxOnBlur={this.handleSearchBoxOnBlur}
        />
        <MapControls
          setSatelliteMap={this.setSatelliteMap}
          setRoadsMap={this.setRoadsMap}
          setHybridMap={this.setHybridMap}
        />
        <div ref={(el) => (this.mapContainer = el)} className="mapContainer" />
      </>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      searchErrorMessage: "",
      resultsVisible: true,
      markers: [],
      userGeoLocation: null
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.setRoadsMap = this.setRoadsMap.bind(this);
    this.setSatelliteMap = this.setSatelliteMap.bind(this);
    this.setHybridMap = this.setHybridMap.bind(this);
    this.handleAddressClick = this.handleAddressClick.bind(this);
    this.handleSearchBoxOnFocus = this.handleSearchBoxOnFocus.bind(this);
    this.handleSearchBoxOnBlur = this.handleSearchBoxOnBlur.bind(this);
    this.defaultStyle = 'mapbox://styles/mapbox/satellite-streets-v11?optimize=true';
  }

  setRoadsMap() {
    this.map.setStyle("mapbox://styles/mapbox/streets-v11?optimize=true");
  }

  setSatelliteMap() {
    this.map.setStyle("mapbox://styles/mapbox/satellite-v9?optimize=true");
  }

  setHybridMap() {
    this.map.setStyle("mapbox://styles/mapbox/satellite-streets-v11?optimize=true");
  }

  async handleSearch(query) {
    if (query) {
      // Clear existing result
      this.setState({
        searchResults: [],
        resultsVisible: false,
      });

      try {
        const results = await this.client.lookupPostcode({ postcode: query });

        if (results.length === 0) {
          this.setState({
            searchErrorMessage: "Postcode not found",
          });
        } else {
          this.setState({
            searchResults: results,
            searchErrorMessage: "",
            resultsVisible: true,
          });
        }
      } catch (err) {
        this.setState({ searchErrorMessage: `API/Server Error` });
        console.error(err.message);
      }
    }
  }

  clearMarkers() {
    this.state.markers.map((marker) => marker.remove());
    this.setState({ markers: [] });
  }

  handleAddressClick(address) {
    this.clearMarkers();

    let popupHtml = "";
    popupHtml += address.line_1 && address.line_1 + "<br>";
    popupHtml += address.line_2 && address.line_2 + "<br>";
    popupHtml += address.line_3 && address.line_3 + "<br>";
    popupHtml += address.post_town && address.post_town + "<br>";
    popupHtml += address.county && address.county + "<br>";
    popupHtml += address.postcode && address.postcode + "<br>";
    popupHtml += `<a href="http://maps.apple.com?q=${address.latitude},${address.longitude}">🚗 Navigate</a>`;

    let popup = new mapboxgl.Popup({ offset: 20 }).setHTML(popupHtml);

    let marker = new mapboxgl.Marker()
      .setLngLat([address.longitude, address.latitude])
      .setPopup(popup)
      .addTo(this.map);

    this.map.setZoom(17).panTo([address.longitude, address.latitude]);

    this.setState({ markers: [marker], resultsVisible: false });
  }

  handleSearchBoxOnFocus() {
    this.setState({ resultsVisible: true });
  }

  handleSearchBoxOnBlur(event) {
    let tgt = event.relatedTarget;
    // Only hide results if the user has selected the map canvas
    tgt &&
      tgt.constructor.name === "HTMLCanvasElement" &&
      this.setState({ resultsVisible: false });
  }

  componentDidMount() {
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: this.defaultStyle,
      center: [-3.540170, 52.448929],
      zoom: 6.5,
    });

    this.client = new postcodeClient({
      api_key: process.env.REACT_APP_IDEAL_POSTCODES_KEY,
    });

    this.geoLocEl = document.createElement('div');
    this.geoLocEl.className = 'vehicle-marker';
    //52.680786,-3.091634
    this.geoLocMarker = new mapboxgl.Marker(this.geoLocEl);
    this.geoLocMarker.setLngLat([-3.0916, 52.6807]);
    this.geoLocMarker.addTo(this.map);

    if ('geolocation' in navigator) {
      navigator.geolocation.watchPosition((position) => {
        this.geoLocMarker.setLngLat([position.coords.longitude, position.coords.latitude]);
      });
    }
  }

  componentWillUnmount() {
    this.map = null;
    this.client = null;
  }
}

export default MainSearchMap;
