import { ReactComponent as Logo } from "../polaris.svg";
import speedLogo from "../speed.png";

function LandingPage() {
  return (
    <>
      <div className="landingPage">
        <Logo width="6em" height="6em" />
        <h1>Polaris</h1>
        <h2>Helping you find your way</h2>
        <p>Search for the precise location of individual UK properties.</p>
        <p><a className='button' href="/m">Enter Polaris</a></p>
        <p>
          &copy; {new Date().getFullYear()}{" "}
          <a href="http://speedwelshpool.com">Speed Welshpool</a>
        </p>
        <p>
          <img
            className="landingPageSpeedLogo"
            src={speedLogo}
            alt="Speed Logo"
          />
        </p>
      </div>
    </>
  );
}
export default LandingPage;
